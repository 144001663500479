import React from 'react';
import { createState, useState } from '@hookstate/core';
import { UserModel } from '~types/userModel';
import { PagedResultModel } from '~types/pagedResultModels';

const usersStore = createState<UserModel[]>([]);
const userStore = createState<UserModel | undefined>(undefined);
const userPagedStore = createState<PagedResultModel<UserModel>>({
  totalPages: 0,
  totalRecords: 0,
  currentPage: 0,
  pageSize: 0,
  records: [],
});

export { usersStore, userStore, userPagedStore };
