import React, { Suspense } from 'react';
import logo from './logo.svg';
import './App.less';
import { Button, message, notification } from 'antd';
import ROUTES, { RenderRoutes } from './routes';

import './fontawesome';

// global setting message config
message.config({
  top: 10,
  duration: 3,
  maxCount: 3,
});

notification.config({
  placement: 'bottomRight',
  bottom: 24,
  duration: 3,
});

function App() {
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <RenderRoutes routes={ROUTES} />
    </Suspense>
  );
}

export default App;
