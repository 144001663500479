import { PermissionModel } from '~types/permissionModels';
import { ProfileModel } from '~types/userModel';

const Storages = {
  getJson(key: string): any {
    const storedValues = localStorage.getItem(key);
    return storedValues ? JSON.parse(storedValues) : null;
  },

  getString(key: string) {
    return localStorage.getItem(key);
  },

  getProfile(): ProfileModel | null {
    const profile = this.getString('profile');
    if (profile) return JSON.parse(profile) as ProfileModel;

    return null;
  },

  getAccessToken() {
    return this.getString('accessToken');
  },

  saveValue(key: string, value: string) {
    localStorage.setItem(key, value);
  },

  remove(key: string) {
    localStorage.removeItem(key);
  },
};

export default Storages;
