import React from 'react';
import PropTypes from 'prop-types';
import { message, Upload } from 'antd';
import AppIcon from './AppIcon';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import Storages from '~utils/storages';
import { DraggerProps } from 'antd/lib/upload';

type FileUploaderProps = DraggerProps & {
  //headers?: { Authorization: '' };
  uploadText: string;
  uploadHint: string;
  textButton: string;
};

const { Dragger } = Upload;

const FileUploader = (props: FileUploaderProps) => (
  <>
    <Dragger {...props}>
      <p className='ant-upload-drag-icon'>
        <AppIcon icon={faInbox.iconName} />
      </p>
      <p className='ant-upload-text'>{props.uploadText}</p>
      <p className='ant-upload-hint'>{props.uploadHint}</p>
    </Dragger>
  </>
);

FileUploader.defaultProps = {
  uploadText: 'Click or drag file to this area to upload',
  uploadHint:
    'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
  textButton: 'Upload',
  onChange: (info: any) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(['uploading', info.file, info.fileList]);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      console.log('file upload failed');
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

FileUploader.propTypes = {
  uploadText: PropTypes.string,
  uploadHint: PropTypes.string,
  textButton: PropTypes.string,
  uploadButton: PropTypes.element,
  onSubmit: PropTypes.func,
};

FileUploader.defaultProps = {
  headers: { Authorization: `Bearer ${Storages.getAccessToken()}` },
};

export default FileUploader;
