import * as React from 'react';
import {
  Button,
  Drawer,
  Form,
  Input,
  notification,
  Space,
  Tabs,
  Table,
} from 'antd';
import { RoleModel } from '~types/roleModels';
import { createState, useState } from '@hookstate/core';
import useRoleApi from '~root/src/apis/useRoleApi';
import _ from 'lodash';
import { PermissionModel } from '~types/permissionModels';
import { ResourceModel } from '~types/resourceModels';
import RolePermission from './RolePermission';
import { RESOURCES } from './Role';
import MESSAGES from '~constants/messages';

export interface CreateRoleProps {
  firstName: string;
  lastName: string;
  email: string;
  department: any;
  role: any;
}

interface RoleFormProps {
  data?: RoleModel;
  visible: boolean;
  closeDrawer(): void;
  onSuccess?(): void;
}

const { TabPane } = Tabs;

export const CHANGED_PERMISSIONS = createState<{ [key: string]: any }>({});
export const ROLE_STATE_PERMISSIONS = createState<PermissionModel[]>([]);

export default function CreateRoleFormDrawer({
  data,
  visible,
  closeDrawer,
  onSuccess,
}: RoleFormProps) {
  const [form] = Form.useForm();
  const { create, update } = useRoleApi();
  const saving = useState(false);
  const roleRights = useState<PermissionModel[]>([]);
  const resources = useState(RESOURCES);
  const changedPermissions = useState(CHANGED_PERMISSIONS);
  const rolesPermissions = useState(ROLE_STATE_PERMISSIONS);

  const userColumns = React.useMemo(
    () => [
      {
        title: 'Username',
        dataIndex: 'userName',
      },
    ],
    []
  );

  const createRolePermissions = () => {
    const permissions = rolesPermissions.value.map((rp) => ({
      ...rp,
      deny: !(rp.canRead || rp.canCreate || rp.canUpdate || rp.canDelete),
      full:
        (rp.canRead && rp.canCreate && rp.canUpdate && rp.canDelete) || rp.full,
    }));

    return permissions;
  };

  const onFinish = async () => {
    const values: RoleModel = await form.validateFields();

    try {
      saving.set(true);
      const result = data?.id
        ? await update({
            ...values,
            id: data.id,
            permissions: createRolePermissions(),
          })
        : await create(values);
      if (
        result.status === 200 ||
        result.status === 201 ||
        result.status === 204
      ) {
        notification.success({
          key: 'role_create_success',
          message: MESSAGES.saveSuccessfully,
          //description: "Click 'refresh' icon on data grid to see new data",
        });
        if (typeof onSuccess === 'function') onSuccess();
      }
    } catch (error) {
      // const {
      //   data: { status, detail, title },
      // } = error;
      notification.error({
        key: 'role_create_failed',
        message: MESSAGES.saveFailure,
        description: `Error: ${error}`,
      });
    } finally {
      resetData();
      // closeDrawer();
      // saving.set(false);
    }
  };

  const resetData = () => {
    form.resetFields();
    closeDrawer();
    rolesPermissions.set([]);
    saving.set(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setRights = () => {
    const _permissions = new Map<string, PermissionModel>();
    data?.permissions.map((r) => _permissions.set(r.id, r));

    const rights: { [key: string]: ResourceModel } = {};
    resources.value.map((rs) => (rights[rs.id] = rs));

    const keys = _.keys(rights);
    keys.map((key) => {
      const val = _permissions.get(key);
      if (val)
        rights[key] = {
          ...rights[key],
          deny: !(
            val.canRead ||
            val.canCreate ||
            val.canUpdate ||
            val.canDelete
          ),
          canRead: val.canRead,
          canCreate: val.canCreate,
          canUpdate: val.canUpdate,
          canDelete: val.canDelete,
          full: val.canRead && val.canCreate && val.canUpdate && val.canDelete,
        };
    });

    roleRights.set(_.values(rights));
  };

  React.useEffect(() => {
    setRights();
    form.resetFields();
  }, [data]);

  return (
    <Drawer
      title={data ? data.name : 'Create a new role'}
      width={600}
      onClose={resetData}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      mask={true}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Space>
            <Button onClick={resetData}>Cancel</Button>
            <Button
              onClick={onFinish}
              type='primary'
              htmlType='submit'
              loading={saving.value}
            >
              Save
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        form={form}
        name='userForm'
        layout={'vertical'}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 24 }}
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Tabs tabPosition={'top'}>
          <TabPane tab='Information' key='info'>
            <Form.Item
              label='Name'
              name='name'
              rules={[
                {
                  required: true,
                  //min: 5,
                  message: '',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </TabPane>
          {data && (
            <TabPane tab='Permission' key='permission' forceRender={true}>
              <RolePermission rights={roleRights.value} />
            </TabPane>
          )}
          {data && (
            <TabPane tab='User' key='users'>
              <Table
                dataSource={data?.users}
                columns={userColumns}
                pagination={false}
                rowKey='id'
              />
            </TabPane>
          )}
        </Tabs>
      </Form>
    </Drawer>
  );
}
