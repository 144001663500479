import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Popconfirm, Space } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckboxOnChangeData } from '~components/role/RolePermission';
import { COLORS } from '~constants/colors';
import AppIcon from './AppIcon';

interface CellProps {
  record: any;
  text?: any;
  index?: any;
}
interface ActionCellProps extends CellProps {
  canRead?: boolean;
  canEdit: boolean;
  canDelete: boolean;
  handleRead?(key?: string): void;
  handleEdit?(key?: string): void;
  handleDelete?(key: string): void;
  iconRead?: IconProp;
  readTitle?: string;
  iconEdit?: IconProp;
  editTitle?: string;
  iconDelete?: IconProp;
  deleteTitle?: string;
  linkTo?: string;
  editLink?: string;
}

export const ActionCell = ({
  record,
  text,
  index,
  canRead = false,
  canEdit,
  canDelete,
  handleRead,
  handleEdit,
  handleDelete,
  iconRead = faEye.iconName,
  readTitle = 'View',
  iconEdit = faEdit.iconName,
  editTitle = 'Edit',
  iconDelete = faTrash.iconName,
  deleteTitle = 'Delete',
  linkTo,
  editLink,
}: ActionCellProps) => {
  const { id: key } = record;
  const ConfirmationDelete = () => (
    <Popconfirm
      title={`Delete this item?`}
      onConfirm={() => handleDelete && handleDelete(key)}
      onCancel={() => console.log('cancel')}
    >
      <AppIcon
        icon={iconDelete}
        cursor='pointer'
        size='lg'
        title={deleteTitle}
      />
    </Popconfirm>
  );

  return (
    <Space>
      {/* {canRead && (
        <a
          href={linkTo ?? ''}
          target='_blank'
          //onClick={() => handleRead && handleRead(key)}
        >
          <AppIcon
            icon={iconRead}
            cursor='pointer'
            size='lg'
            title={readTitle}
            //onClick={() => handleRead && handleRead(key)}
          />
        </a>
      )} */}
      {canRead && linkTo && (
        <a
          style={{ color: COLORS.darkGrey }}
          href={linkTo ?? ''}
          target='_blank'
          //onClick={() => handleRead && handleRead(key)}
        >
          <AppIcon
            icon={iconRead}
            cursor='pointer'
            size='lg'
            title={readTitle}
            //onClick={() => handleRead && handleRead(key)}
          />
        </a>
      )}
      {canRead && !linkTo && (
        <AppIcon
          icon={iconRead}
          cursor='pointer'
          size='lg'
          title={readTitle}
          onClick={() => handleRead && handleRead(key)}
        />
      )}
      {canEdit && editLink && (
        <a
          style={{ color: COLORS.darkGrey }}
          href={editLink ?? ''}
          target='_blank'
          //onClick={() => handleRead && handleRead(key)}
        >
          <AppIcon
            icon={iconEdit}
            cursor='pointer'
            size='lg'
            title={editTitle}
            //onClick={() => handleEdit && handleEdit(key)}
          />
        </a>
      )}
      {canEdit && !editLink && (
        <AppIcon
          icon={iconEdit}
          cursor='pointer'
          size='lg'
          title={editTitle}
          onClick={() => handleEdit && handleEdit(key)}
        />
      )}
      {canDelete && <ConfirmationDelete />}
    </Space>
  );
};

interface CheckboxCellProps {
  cellKey: string;
  data: any;
  onChange?(e: CheckboxChangeEvent, data?: any): void;
}

export const CheckboxCell = ({
  cellKey,
  data,
  onChange,
}: CheckboxCellProps) => {
  const [checked, setChecked] = useState(data[cellKey]);

  const _onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    if (onChange)
      onChange(e, { ...data, cellKey, checkedValue: e.target.checked });
  };

  useEffect(() => {
    setChecked(data[cellKey]);
  }, [data]);

  return <Checkbox onChange={_onChange} checked={checked} />;
};

interface HtmlCheckboxCellProps {
  cellKey: string;
  data: any;
  onChange?(e: ChangeEvent): void;
}

export const HtmlCheckboxCell = ({
  cellKey,
  data,
  onChange,
}: HtmlCheckboxCellProps) => {
  const [checked, setChecked] = useState<boolean>(data[cellKey]);

  const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    if (onChange) onChange(e);
  };

  useEffect(() => {
    setChecked(data[cellKey]);
  }, [data]);

  return (
    <span>
      <input type='checkbox' onChange={_onChange} checked={checked} />
    </span>
  );
};
