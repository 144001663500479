import { WebStorageStateStore, UserManagerSettings } from 'oidc-client';

const { BASE_URL = '', REACT_APP_AUTH_AUTHORITY: AUTHORITY } = process.env;

const oidc: UserManagerSettings = {
  authority: AUTHORITY,

  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  redirect_uri: `${BASE_URL}${process.env.REACT_APP_AUTH_REDIRECT_URI}`,

  // (string): The URL for the page containing the code handling the silent renew.
  silent_redirect_uri: `${BASE_URL}${process.env.REACT_APP_AUTH_SILENT_REDIRECT_URI}`,

  // (string): The OIDC post-logout redirect URI.
  post_logout_redirect_uri: `${BASE_URL}${process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI}`,

  // (string, default: 'id_token'): The type of response desired from the OIDC provider.
  response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE,

  // (string, default: 'openid'): The scope being requested from the OIDC provider.
  scope: process.env.REACT_APP_AUTH_SCOPE,

  // (boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  automaticSilentRenew: true,

  // (boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  loadUserInfo: false,

  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),

  metadata: {
    issuer: AUTHORITY,
    jwks_uri: `${AUTHORITY}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: `${AUTHORITY}/connect/authorize`,
    token_endpoint: `${AUTHORITY}/connect/token`,
    userinfo_endpoint: `${AUTHORITY}/connect/userinfo`,
    end_session_endpoint: `${AUTHORITY}/connect/endsession`,
    check_session_iframe: `${AUTHORITY}/connect/checksession`,
    revocation_endpoint: `${AUTHORITY}/connect/revocation`,
    introspection_endpoint: `${AUTHORITY}/connect/introspect`,
    grant_types_supported: ['authorization_code'],
  },
};

export default oidc;
