import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

interface ExportDataToExcelProps {
  payload?: any;
  fetch(payload: any): Promise<AxiosResponse>;
}
export const exportDataToExcel = async ({
  payload,
  fetch,
}: ExportDataToExcelProps) => {
  //props.store.uiStore.toggleLoading();
  //let hideLoadingMsg = message.loading('Exporting in progress...', 0);
  const { status, headers, data } = await fetch(payload);
  if (status === 200) {
    if (data.byteLength === 0) {
      console.log('no data from server');
      //return false;
    } else {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(headers['content-type']);
      if (matches != null && matches[1]) {
        var filename = matches[1].split(',')[0];
        var blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        saveAs(blob, filename);
      }
    }
  }
};
