import * as React from 'react';
import { styles } from '~styles/Styles';

interface BoxProps {
  children: React.ReactNode;
  height?: string;
  bgColor?: string;
  width?: string;
}
const Box = ({ height, width = '100%', bgColor, children }: BoxProps) => (
  <div style={{ ...styles.box, height, width, backgroundColor: bgColor }}>
    {children}
  </div>
);

export default Box;
