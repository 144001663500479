import useApi from '~hooks/useApi';
import { DataTableResult } from '~types/dataTable';
import { ResourceModel } from '~types/resourceModels';

const endpoint = 'resources';
export default function useResourceApi() {
  const { instance: api, get, post, put, delete: remove } = useApi();
  const fetch = async () => {
    return await get(endpoint);
  };

  const create = async (payload: ResourceModel) => {
    return await post(endpoint, payload);
  };

  const update = async (payload: ResourceModel) => {
    return await put(`${endpoint}/${payload.id}`, payload);
  };

  const destroy = async (id: string) => {
    return await remove(`${endpoint}/${id}`);
  };

  return {
    fetch,
    create,
    update,
    destroy,
  };
}
