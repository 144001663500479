const COLUMNS_USER_FORM = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 6,
  xl: 6,
  xxl: 6,
};

const ASSET_GRID_COLUMNS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 6,
  xxl: 6,
};

const ASSET_GRID_COLUMNS2 = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 8,
  xxl: 8,
};

const GRID_COLUMNS_12 = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
};

const GRID_COLUMNS_8 = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 8,
  xxl: 8,
};

const GRID_COLUMNS_6 = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 6,
  xl: 6,
  xxl: 6,
};

export {
  COLUMNS_USER_FORM,
  ASSET_GRID_COLUMNS,
  ASSET_GRID_COLUMNS2,
  GRID_COLUMNS_12,
  GRID_COLUMNS_8,
  GRID_COLUMNS_6,
};
