import * as React from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Space,
  Image,
  Spin,
  Popconfirm,
  Checkbox,
} from 'antd';
import { useState } from '@hookstate/core';
import { createOptions, parseStateAsJson } from '~utils/transform';
import MESSAGES from '~constants/messages';
import Box from '~components/common/Box';
import useAssetApi from '~root/src/apis/useAssetApi';
import TextArea from 'antd/lib/input/TextArea';
import {
  AssetDetailModel,
  AssetModel,
  Attachment,
  UpdateAssetModel,
} from '~types/assetModels';
import AppIcon from '~components/common/AppIcon';
import { faCaretLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import FileUploader from '~components/common/FileUploader';
import { defaultAxiosConfig } from '~constants/api';
import { isImage, isPdf } from '~utils/files';
import { uuid } from 'uuidv4';
import DatePicker from '~components/common/DatePicker';
import dayjs from 'dayjs';
import Storages from '~utils/storages';
import useRoleApi from '~root/src/apis/useRoleApi';
import { ATTACHMENT_TYPE } from '~constants/attachment';
import { FilePdfOutlined } from '@ant-design/icons';
import { downloadFile } from '~utils/downloadFile';
import { ASSET_GRID_COLUMNS } from '~constants/responsive';
import { DateTime } from '~utils/format';
import useMasterDataApi from '~root/src/apis/useMasterDataApi';
import { FORMATING } from '~constants/formating';
import { ActiveStatus } from '~constants/activeStatus';
import { AssetTypeDetailModel } from '~types/masterData';

interface AssetFormProps {
  data?: AssetDetailModel;
  readonlyFields?: string[];
  onSuccess?(): void;
  onCancel?(): void;
}

export default function AssetForm({
  data,
  readonlyFields,
  onSuccess,
  onCancel,
}: AssetFormProps) {
  const [form] = Form.useForm();
  const { create, update, deleteAttachment } = useAssetApi();
  const {
    getAssetTypes,
    getAssetTypeDetails,
    getAssetClasses,
    getAssetStatuses,
    getCostCenters,
    getLocations,
    getShipTos,
    getSoldTos,
  } = useMasterDataApi();
  const { getRoles } = useRoleApi();
  const loading = useState(false);
  const saving = useState(false);
  const assetTypes = useState([]);
  const assetTypeDetails = useState([]);
  const assetTypeDetailsFiltered = useState([]);
  const assetStatuses = useState([]);
  const assetCostCenters = useState([]);
  const assetClasses = useState([]);
  const ownerRoles = useState([]);
  const locations = useState([]);
  const shipTos = useState([]);
  const soldTos = useState([]);
  const [attachments, setAttachments] = React.useState<Attachment[]>([]);
  const [uploadFiles, setUploadFiles] = React.useState<string[]>([]);
  const assetId = useState('');
  const guid = data?.id ?? uuid();
  const deleted = useState<number>(ActiveStatus.Active);

  const onFinish = async () => {
    const asset: UpdateAssetModel = await form.validateFields();
    const id = data?.id ?? guid;
    const model: UpdateAssetModel = {
      ...asset,
      id: id,
      warrantyEndAt: asset?.warrantyEndAt
        ? dayjs(asset.warrantyEndAt).toISOString()
        : undefined,
      yearOfManufacture: asset?.yearOfManufacture
        ? dayjs(asset.yearOfManufacture).year()
        : undefined,
      uploadFiles: uploadFiles,
    };

    saving.set(true);
    try {
      const result = data?.id ? await update(model) : await create(model);

      if (
        result &&
        (result.status === 200 ||
          result.status === 201 ||
          result.status === 204)
      ) {
        notification.success({
          key: 'asset_create_success',
          message: MESSAGES.saveSuccessfully,
        });

        //setAttachments(uploadFiles);
        if (typeof onSuccess === 'function') onSuccess();
      }
    } catch (error: any) {
      const {
        data: { status, detail },
      } = error;
      //console.log(['error', error]);
      notification.error({
        key: 'asset_create_failed',
        message: MESSAGES.saveFailure,
        description: `Error: ${status} / ${detail}`,
      });
    } finally {
      resetData(data === undefined);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    assetId.set(changedValues.assetId);
  };

  const prepareData = async () => {
    loading.set(true);
    const [
      types,
      details,
      statuses,
      costCenters,
      classes,
      locs,
      ships,
      solds,
      roles,
    ] = await Promise.all([
      getAssetTypes({ page: 0, size: 10, search: '', deleted: deleted.value }),
      getAssetTypeDetails({
        page: 0,
        size: 10,
        search: '',
        deleted: deleted.value,
      }),
      getAssetStatuses({
        page: 0,
        size: 10,
        search: '',
        deleted: deleted.value,
      }),
      getCostCenters({ page: 0, size: 10, search: '', deleted: deleted.value }),
      getAssetClasses({
        page: 0,
        size: 10,
        search: '',
        deleted: deleted.value,
      }),
      getLocations({ page: 0, size: 10, search: '', deleted: deleted.value }),
      getShipTos({ page: 0, size: 10, search: '', deleted: deleted.value }),
      getSoldTos({ page: 0, size: 10, search: '', deleted: deleted.value }),
      getRoles({ page: 0, size: 10, search: '', deleted: deleted.value }),
    ]);

    assetTypes.set(types.data.records);
    assetTypeDetails.set(details.data.records);
    assetTypeDetailsFiltered.set(details.data.records);
    assetStatuses.set(statuses.data.records);
    assetCostCenters.set(costCenters.data.records);
    assetClasses.set(classes.data.records);
    locations.set(locs.data.records);
    shipTos.set(ships.data.records);
    soldTos.set(solds.data.records);
    ownerRoles.set(roles.data.records);
    loading.set(false);
  };

  const resetData = (resetFields: boolean = true) => {
    if (resetFields) form.resetFields();
    saving.set(false);
  };

  function uploadFile(info: any) {
    console.log(['uploadFile', info]);

    const { status } = info.file;
    console.log(['status', status]);

    if (status === 'done') {
      // const uploadFiles = info.fileList.map((f: any) => ({
      //   name: f.name,
      //   schemaName: f.name,
      //   blob: '',
      //   id: '',
      //   type: ATTACHMENT_TYPE.Photo,
      // }));
      const uploadFiles = info.fileList.map((f: any) => f.name);
      console.log(['uploadFiles', uploadFiles]);
      setUploadFiles(uploadFiles);
    }

    // const files = info.fileList.map((f: any) => ({
    //   blob: f.thumbUrl.substring('data:image/png;base64'.length),
    //   id: f.uid,
    //   name: f.name,
    //   schemaName: f.name,
    //   type: ATTACHMENT_TYPE.Photo,
    // }));
    // const files = info.fileList.map((f: any) =>
    //   console.log(['f', f, f.name, f.thumbUrl])
    // );

    //setUploadFiles(files);

    // if (status !== 'uploading') {
    //   console.log(['info.file', status, info, info.file, info.fileList]);
    //   // store reference to file upload info for retry.
    //   // setUploadInfo(info);
    // }
    // if (status === 'done') {
    //   // set timeout

    // }
    // // clear error message
    // setMessage();
    // setError(null);
    // let description = '';
    // if (!fileValidations(info.file))
    //   setMessage({
    //     status: 'warning',
    //     description: `${info.file.name} invalid file type`,
    //   });
    // // support only one file per each request.
    // if (info.fileList.length > 1) info.fileList.shift();
    // setUploadFileName(info.file.name);
    // // // store reference to file upload info for retry.
    // // setUploadInfo(info);
    // const { status } = info.file;
    // if (status !== 'uploading') {
    //   console.log(['info.file', status, info, info.file, info.fileList]);
    //   // store reference to file upload info for retry.
    //   // setUploadInfo(info);
    // }
    // if (status === 'done') {
    //   // set timeout
    //   handleProcessingTimeout();
    //   // subscribe to rabbitMQ
    //   subscribeDispoImport(dispoImportCallback);
    //   description = (
    //     <Spin size='large' tip='Processing...' delay={Miliseconds.TwoSec}>
    //       <span>
    //         {info.file.name} file was uploaded and being processed. Please
    //         wait...
    //       </span>
    //     </Spin>
    //   );
    //   // disable form item
    //   setProcessing(true);
    //   setMessage({
    //     status: 'info',
    //     description: description,
    //   });
    //   // call api to start dispo import task
    //   // dispoStore.processDispoImport();
    // } else if (status === 'error') {
    //   if (info.file.error && info.file.error.status === 401)
    //     description = 'Unauthorized';
    //   else {
    //     // enable form item
    //     setProcessing(false);
    //     // Dispo import is being processed by other user.
    //     if (info.file.error && info.file.error.status === 400) {
    //       // set error message
    //       setError(null);
    //       // set message
    //       setMessage({
    //         status: 'warning',
    //         //description: `Dispo import is being processed by other user. The system will automatically process your dispo after previous job is completed.`
    //         description: (
    //           <Spin size='large' tip='Waiting...' delay={Miliseconds.TwoSec}>
    //             <span>
    //               Dispo import is being processed by {info.file.response}. This
    //               block will automatically close after the processing task is
    //               completed.
    //             </span>
    //           </Spin>
    //         ),
    //       });
    //       return;
    //     } else {
    //       description =
    //         info.file.response && info.file.response.ErrorMessage
    //           ? info.file.response.ErrorMessage
    //           : info.file.error && info.file.error.message
    //           ? info.file.error.message
    //           : 'Network Error: Please try again'; //info.file.error;
    //     }
    //   }
    //   setMessage({
    //     status: 'error',
    //     description: `${info.file.name} file upload failed. ${description}`,
    //   });
    // }
  }

  const deleteFile = async (id: string) => {
    const res = await deleteAttachment(id);
    if (res && res.status === 204) {
      removeAttachment(id);
    }
  };

  const removeAttachment = (id: string) => {
    setAttachments(attachments?.filter((atm) => atm.id !== id));
  };

  const filterTypeDetail = (assetTypeId: string) => {
    var details = parseStateAsJson(
      assetTypeDetails.value.filter(
        (x: AssetTypeDetailModel) =>
          x.assetTypeId === assetTypeId || assetTypeId.length === 0
      )
    );

    assetTypeDetailsFiltered.set(details);
  };

  React.useEffect(() => {
    prepareData();
  }, []);

  React.useEffect(() => {
    form.resetFields();
    setAttachments(data?.attachments ?? []);
  }, [data]);

  return (
    <Box>
      {/* <h2>Asset / {data ? `${data.assetId}` : 'Create'}</h2>*/}
      {loading.value && <Spin size='default' />}
      <Space direction='vertical'>
        {/* <Button type='link' onClick={() => history.goBack()}>
          <Space>
            <AppIcon icon={faCaretLeft.iconName} text='Back' />
          </Space>
        </Button> */}

        <Form
          form={form}
          name='assetForm'
          initialValues={{
            ...data,
            warrantyEndAt: data?.warrantyEndAt
              ? dayjs(data?.warrantyEndAt ?? '') //.format('DD-MM-YYYY')
              : null,
            yearOfManufacture: data?.yearOfManufacture
              ? DateTime.toDayjs(data?.yearOfManufacture as number)
              : null,
            agingEnabled: data?.agingSince ? true : false,
            comments: '', // force user put comment
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          style={{ marginTop: 10 }}
        >
          <Row gutter={16}>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Serial No.'
                name='serialNo'
                rules={[{ required: true, message: '' }]}
              >
                <Input disabled={data && data.id ? true : false} />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Asset Type'
                name='assetTypeId'
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  //style={{ width: '100%' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(assetTypes.value, 'id', 'name')}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onSelect={filterTypeDetail}
                  allowClear
                  onClear={() => filterTypeDetail('')}
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Asset Type Details'
                name='assetTypeDetailId'
                rules={[{ required: false, message: '' }]}
              >
                <Select
                  //style={{ width: '100%' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(
                    assetTypeDetailsFiltered.value,
                    'id',
                    'name'
                  )}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Details'
                name='details'
                rules={[
                  {
                    required: true,
                    //min: 10,
                    message: '',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Cost Center'
                name='costCenterId'
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  //style={{ width: '100%' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(assetCostCenters.value, 'id', 'name')}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Asset Class'
                name='assetClassId'
                rules={[{ required: false, message: '' }]}
              >
                <Select
                  style={{ width: '95%' }}
                  options={createOptions(assetClasses.value, 'id', 'code')}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Sold-To'
                name='soldToId'
                rules={[{ required: true, message: '' }]}
              >
                {/* <Input /> */}
                <Select
                  //style={{ width: '200px' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(soldTos.value, 'id', 'name')}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Ship-To'
                name='shipToId'
                rules={[{ required: false, message: '' }]}
              >
                {/* <Input /> */}
                <Select
                  //style={{ width: '200px' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(shipTos.value, 'id', 'name')}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Status'
                name='assetStatusId'
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  //style={{ width: '90%' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(assetStatuses.value, 'id', 'name')}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Location'
                name='locationId'
                rules={[{ required: false, message: '' }]}
              >
                <Select
                  //style={{ width: '90%' }}
                  showSearch={true}
                  optionFilterProp='children'
                  options={createOptions(locations.value, 'id', 'name')}
                  filterOption={(input, option) => {
                    const label = option?.label?.toString() ?? '';
                    return (
                      label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Year of Manufacture'
                name='yearOfManufacture'
                rules={[{ required: false, message: '' }]}
              >
                <DatePicker picker='year' />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Warranty (End date)'
                name='warrantyEndAt'
                rules={[
                  {
                    required: false,
                    //pattern: regEx.yyyymmdd,
                    //max: 10,
                    message: '',
                  },
                ]}
              >
                <DatePicker
                  format={FORMATING.DEFAULT_DATE}
                  picker='date'
                  //disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Asset QR Code'
                name='assetId'
                rules={[
                  {
                    required: true,
                    //min: 10,
                    message: '',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Asset No. (SAP)'
                name='assetNo'
                rules={[{ required: false, message: '' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col {...ASSET_GRID_COLUMNS}>
              <Form.Item
                label='Aging'
                name='agingEnabled'
                valuePropName='checked'
                rules={[
                  {
                    required: false,
                    message: '',
                  },
                ]}
              >
                <Checkbox />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label='Other'
                name='others'
                rules={[{ required: false, message: '' }]}
              >
                <TextArea rows={3} maxLength={500} showCount />
              </Form.Item>
            </Col>
          </Row>
          {/* </Form> */}
          <label>Attachments</label>
          {attachments && (
            <>
              <Image.PreviewGroup>
                {attachments
                  .filter((atm) => atm.type === ATTACHMENT_TYPE.Photo)
                  .map((atm: Attachment) => (
                    <span key={atm.id}>
                      <Image
                        width={200}
                        src={`data:image/png;base64,${atm.blob}`}
                      />
                      <Popconfirm
                        title={`Delete ${atm.name}?`}
                        okText='Delete'
                        cancelText='Cancel'
                        onConfirm={() => deleteFile(atm.id)}
                        placement='top'
                      >
                        <AppIcon icon={faTrash} cursor='pointer' />
                      </Popconfirm>
                    </span>
                  ))}
              </Image.PreviewGroup>
              <div>
                {attachments
                  .filter((atm) => atm.type === ATTACHMENT_TYPE.Pdf)
                  .map((atm: Attachment) => (
                    <span key={atm.id}>
                      <Button
                        icon={<FilePdfOutlined />}
                        type='link'
                        onClick={() => downloadFile(atm)}
                      >
                        {' '}
                        {atm.name}
                      </Button>
                      <Popconfirm
                        title={`Delete ${atm.name}?`}
                        okText='Delete'
                        cancelText='Cancel'
                        onConfirm={() => deleteFile(atm.id)}
                        placement='top'
                      >
                        <AppIcon icon={faTrash} cursor='pointer' />
                      </Popconfirm>
                    </span>
                  ))}
              </div>
            </>
          )}

          <FileUploader
            //fileList={fileList.filter((f) => fileValidations(f))}
            accept={
              'image/*,.pdf' // image & pdf
            }
            action={`${defaultAxiosConfig.baseURL}assets/attachments/upload`}
            headers={{ Authorization: `Bearer ${Storages.getAccessToken()}` }}
            //disabled={assetId.value.length === 0}
            uploadHint="Drag 'n' drop some files here, or click to select (.jpg .jpeg .png or .pdf)"
            data={{ id: guid }}
            onChange={uploadFile}
            maxCount={20}
            multiple={true}
            listType={'picture'}
            beforeUpload={(file) => {
              //validate extension & content type
              return isImage(file) || isPdf(file);
            }}
          />
          <Row style={{ marginTop: '5px' }}>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Space>
                <Form.Item
                  label='Comments'
                  name='comments'
                  rules={[{ required: false, message: '' }]}
                >
                  <TextArea
                    placeholder='Describe what changes are made'
                    rows={3}
                    style={{ width: 400 }}
                  />
                </Form.Item>
                <Button danger htmlType='reset' onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  type='primary'
                  htmlType='submit'
                  //onClick={onFinish}
                  loading={saving.value}
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Space>
    </Box>
  );
}
