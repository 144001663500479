import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button, Input, Space } from 'antd';
import _ from 'lodash';
import * as React from 'react';
import AppIcon from './AppIcon';

interface SearchBoxProps {
  placeholder?: string;
  size?: 'small' | 'middle' | 'large';
  width?: number | string;
  onChange?(search: string): void;
  onEnter?(search: string): void;
}

export default function SearchBox({
  placeholder = 'Search...',
  size = 'middle',
  width = 200,
  onChange,
  onEnter,
}: SearchBoxProps) {
  const inputRef = React.createRef<Input>();
  //const [search, setSearch] = React.useState("");
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const debounced = _.throttle(
      () => onChange && onChange(e.target.value),
      1000,
      {
        //maxWait: 2000,
        leading: false,
        trailing: true,
      }
    );

    _.throttle(() => debounced(), 1000)();
  };

  return (
    <>
      <Input
        ref={inputRef}
        size={size}
        placeholder={placeholder}
        style={{ width, borderRadius: 10 }}
        onChange={handleOnChange}
        onPressEnter={(e) =>
          inputRef.current?.input &&
          onEnter &&
          onEnter(inputRef.current?.input.value)
        }
        prefix={<AppIcon icon={faSearch.iconName} />}
      />
    </>
  );
}
