import { useState } from '@hookstate/core';
import { Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React from 'react';
import { CheckboxCell } from '~components/common/Cells';
import { PermissionModel } from '~types/permissionModels';
import { ROLE_STATE_PERMISSIONS } from './CreateRoleFormDrawer';

interface RolePermissionProps {
  rights: PermissionModel[];
}

export interface CheckboxOnChangeData {
  id: string;
  cellKey: string;
  checkedValue: boolean;
}

export default function RolePermission({ rights }: RolePermissionProps) {
  const [data, setData] = React.useState(rights);
  const [changedData, setChangedData] = React.useState<{ [key: string]: any }>(
    {}
  );
  const [loading, setLoading] = React.useState(false);
  const rolePermissions = useState(ROLE_STATE_PERMISSIONS);

  const handleOnChange = (
    e: CheckboxChangeEvent,
    data: CheckboxOnChangeData
  ) => {
    const { id, cellKey: key, checkedValue } = data;
    const changes = changedData;
    if (!changes[id]) changes[id] = {};

    changes[id][key] = checkedValue;

    if (key === 'full') {
      changes[id]['canRead'] = checkedValue;
      changes[id]['canCreate'] = checkedValue;
      changes[id]['canUpdate'] = checkedValue;
      changes[id]['canDelete'] = checkedValue;
    } else {
      if (!checkedValue) changes[id]['full'] = false;
      else if (
        changes[id]['canRead'] &&
        changes[id]['canCreate'] &&
        changes[id]['canUpdate'] &&
        changes[id]['canDelete']
      )
        changes[id]['full'] = true;
    }

    const permissions = rights.map((r) => {
      let right = { ...r };
      if (changes[r.id]) {
        right = {
          ...right,
          ...changes[r.id],
        };
      }
      return {
        ...right,
        full:
          right.canRead &&
          right.canCreate &&
          right.canUpdate &&
          right.canDelete,
      };
    });

    rolePermissions.set(permissions);
  };

  const columns = React.useMemo(
    () =>
      [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },

        {
          title: 'Read',
          dataIndex: 'canRead',
          key: 'canRead',
          render: (_: any, record: any) => (
            <CheckboxCell
              cellKey={'canRead'}
              data={record}
              onChange={handleOnChange}
            />
          ),
        },
        {
          title: 'Create',
          dataIndex: 'canCreate',
          key: 'canCreate',
          render: (_: any, record: any) => (
            <CheckboxCell
              cellKey={'canCreate'}
              data={record}
              onChange={handleOnChange}
            />
          ),
        },
        {
          title: 'Update',
          dataIndex: 'canUpdate',
          key: 'canUpdate',
          render: (_: any, record: any) => (
            <CheckboxCell
              cellKey={'canUpdate'}
              data={record}
              onChange={handleOnChange}
            />
          ),
        },
        {
          title: 'Delete',
          dataIndex: 'canDelete',
          key: 'canDelete',
          render: (_: any, record: any) => (
            <CheckboxCell
              cellKey={'canDelete'}
              data={record}
              onChange={handleOnChange}
            />
          ),
        },
        {
          title: 'Full',
          dataIndex: 'full',
          key: 'full',
          render: (_: any, record: any) => (
            <CheckboxCell
              cellKey={'full'}
              data={record}
              onChange={handleOnChange}
            />
          ),
        },
      ] as Array<any>,
    [rights]
  );

  const setRightsData = async () => {
    setLoading(true);
    setData(rights);
    setLoading(false);
  };

  React.useEffect(() => {
    setRightsData();

    return () => {
      setChangedData({});
    };
  }, [rights]);

  return (
    <>
      <Table
        dataSource={
          rolePermissions.value && rolePermissions.value.length > 0
            ? rolePermissions.value
            : data
        }
        columns={columns}
        loading={loading}
        pagination={false}
        rowKey='id'
      />
    </>
  );
}
