import React from 'react';
import { Route, Redirect, matchPath } from 'react-router-dom';

//import LoadingOverlay from '~components/LoadingOverlay'
import useAuth from '~hooks/useAuth';
import { routes as ROUTES } from '~constants/path';
//import { status as STATUS } from '~constants/common'

export default function AppRoute({
  key,
  path,
  routes,
  isPublic = false,
  component: Component,
}: any) {
  //const { authStatus, isLoggedIn } = useAuth()
  const { isLoggedIn, signinRedirect } = useAuth();
  const isPrivateRoute = !isPublic;

  return (
    <Route
      key={key}
      path={path}
      render={(props) => {
        const { location } = props;

        if (isPrivateRoute) {
          // if (authStatus === STATUS.idle) {
          //     return <LoadingOverlay />
          // }

          const root = matchPath(location.pathname, {
            exact: true,
            path: ROUTES.root,
          });

          // if (isLoggedIn() && root) {
          //   return <Redirect to={ROUTES.main} />;
          // }

          if (!isLoggedIn()) {
            // return (
            //   <Redirect
            //     to={{
            //       pathname: ROUTES.auth,
            //       state: {
            //         from: location,
            //       },
            //     }}
            //   />
            // );
            //signinRedirect(`${location.pathname}${location.search}`);
            signinRedirect();
          }
        }

        return <Component {...props} routes={routes} />;
      }}
    />
  );
}
