import mapValues from 'lodash/mapValues'
import identity from 'lodash/identity'
import compose from 'lodash/fp/compose'
import pickBy from 'lodash/fp/pickBy'
import fpMapValues from 'lodash/fp/mapValues'

import { Path } from '~types/route'

interface PathValue {
    route: string,
    key: string,
    aggregateType?: string
}

export const path: Record<keyof Path, PathValue> = {
    root: {
        route: '/',
        key: 'root',
    },

    auth: {
        route: '/auth',
        key: 'auth',
    },
    // signInRedirect: {
    //     route: process.env.AUTH_REDIRECT_URI,
    //     key: 'auth.signin-redirect',
    // },
    // signOutRedirect: {
    //     route: process.env.AUTH_POST_LOGOUT_REDIRECT_URI,
    //     key: 'auth.signout-redirect',
    // },

    main: {
        route: '/main',
        key: 'main',
    },
    dashboard: {
        route: '/dashboard',
        key: 'dashboard',
    },
    user: {
        route: '/user',
        key: 'user',
    },
    role: {
        route: '/role',
        key: 'role',
    },

    // profile: {
    //     route: '/profile',
    //     key: 'users.profile',
    // },

    notFound: {
        route: '/not-found',
        key: 'not-found',
    },
}

export const routes = mapValues(path, 'route') as Record<keyof Path, PathValue['route']>
export const keys = mapValues(path, 'key') as Record<keyof Path, PathValue['key']>

export const aggregateType = compose(
    pickBy(identity),
    fpMapValues('aggregateType'),
)(path) as Record<keyof Path, PathValue['aggregateType']>

export type AggregateType = (typeof aggregateType)[keyof typeof aggregateType]
