import authStore from '~stores/authStore';
import OIDC from '~constants/oidc';
import { useState } from '@hookstate/core';
import { User, UserManager } from 'oidc-client';
import { useEffect, useRef } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import Storages from '~utils/storages';

export const manager = new UserManager(OIDC);

// console.log('oidc', OIDC);

const tokenKey = 'accessToken';
const profileKey = 'profile';

const saveToken = (token: string) => Storages.saveValue(tokenKey, token);
const removeToken = () => Storages.remove(tokenKey);
const saveProfile = (profile: string) =>
  Storages.saveValue(profileKey, profile);
const removeProfile = () => Storages.remove(profileKey);

function useAuth() {
  const userState = useState(authStore);
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = () =>
    Storages.getAccessToken() !== null ||
    (!!userState.value && userState.value?.access_token.length > 0);

  let userManager = useRef<UserManager>();

  useEffect(() => {
    userManager.current = manager;

    const onUserLoaded = (user: User) => {
      console.log(`user loaded: ${user}`);
      //store.dispatch(storeUser(user))
      userState.set(user);
      saveToken(user.access_token);
      saveProfile(user.profile?.profile || '');
    };

    const onUserUnloaded = () => {
      //setAuthHeader(null)
      console.log(`user unloaded`);
      userState.set(null);
      removeToken();
      removeProfile();
    };

    const onAccessTokenExpiring = () => {
      console.log(`user token expiring`);
    };

    const onAccessTokenExpired = () => {
      console.log(`user token expired`);
      removeToken();
      removeProfile();
    };

    const onUserSignedOut = () => {
      console.log(`user signed out`);
      removeToken();
      removeProfile();
    };

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded);
    userManager.current.events.addUserUnloaded(onUserUnloaded);
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.current.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager?.current?.events.removeUserLoaded(onUserLoaded);
      userManager?.current?.events.removeUserUnloaded(onUserUnloaded);
      userManager?.current?.events.removeAccessTokenExpiring(
        onAccessTokenExpiring
      );
      userManager?.current?.events.removeAccessTokenExpired(
        onAccessTokenExpired
      );
      userManager?.current?.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [manager]);

  const signinRedirect = async (returnUrl?: string) => {
    const { pathname, search } = location;
    const redirectToUrl = returnUrl || `${pathname}${search}`;
    return await manager.signinRedirect({
      state: { returnUrl: redirectToUrl },
    });
  };

  const signinRedirectCallback = async () => {
    var result = await manager.signinRedirectCallback();
    if (result?.state) {
      const { returnUrl } = result.state;
      if (isWhitelist(returnUrl)) history.replace(returnUrl);
    } else {
      history.push('/');
    }
  };

  const signoutRedirect = async () => {
    await manager.clearStaleState();
    await manager.removeUser();
    return await manager.signoutRedirect();
  };

  const signoutRedirectCallback = async () => {
    await manager.clearStaleState();
    await manager.removeUser();
    return await manager.signoutRedirectCallback();
  };

  const signinSilent = async () => {
    //console.log(['signinSilent']);
    return await manager.signinSilent();
  };

  const signinSilentCallback = async () => {
    //console.log(['signinSilentCallback']);
    return await manager.signinSilentCallback();
  };

  const logout = async () => {
    await manager.signoutRedirect();
    await manager.clearStaleState();
    removeToken();
  };

  const isWhitelist = (url: string) => {
    return (
      url && !url.includes('auth') && !url.includes('signin-oidc') //&&
      //!url.includes('signout-oidc')
    );
  };

  return {
    // authRequest,
    //authStatus,
    isLoggedIn,

    signinRedirect,
    signinRedirectCallback,

    signinSilent,
    signinSilentCallback,

    signoutRedirect,
    signoutRedirectCallback,

    logout,
  };
}

export default useAuth;
