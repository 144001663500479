import { useState } from '@hookstate/core';
import permissionStore from '~stores/permissionStore';
import { ResourcesRights } from '~types/resourceModels';
import useProfile from './useProfile';

export default function useRights() {
  const userRights = useState(permissionStore);
  const user = useProfile();

  const hasRights = (key: ResourcesRights) => {
    const right = userRights.value[key];

    return (
      (user && user.profile?.isAdmin) ||
      (right &&
        (right.canRead ||
          right.canCreate ||
          right.canUpdate ||
          right.canDelete))
    );
  };

  const hasCreateRights = (key: ResourcesRights) => {
    const right = userRights.value[key];

    return right && right.canCreate;
  };

  const hasReadRights = (key: ResourcesRights) => {
    const right = userRights.value[key];

    return right && right.canRead;
  };

  const hasUpdateRights = (key: ResourcesRights) => {
    const right = userRights.value[key];

    return right && right.canUpdate;
  };

  const hasDeleteRights = (key: ResourcesRights) => {
    const right = userRights.value[key];

    return right && right.canDelete;
  };

  return {
    userRights,
    hasRights,
    hasCreateRights,
    hasReadRights,
    hasUpdateRights,
    hasDeleteRights,
  };
}
