import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FORMATING } from '~constants/formating';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const DateTime = {
  toStandardDate(date: string | number | Date | Dayjs) {
    if (dayjs(date).isValid())
      return dayjs.utc(date).tz('Asia/Bangkok').format(FORMATING.DEFAULT_DATE);

    return date;
  },
  toStandardDateTime(date: string | number | Date | Dayjs) {
    if (dayjs(date).isValid())
      return dayjs
        .utc(date)
        .tz('Asia/Bangkok')
        .format(FORMATING.DEFAULT_DATETIME);

    return date;
  },
  toDayjs(year: number) {
    return dayjs.utc(new Date(year, 1, 1));
  },
  timeFromNow() {
    return dayjs.utc().tz('Asia/Bangkok').fromNow();
  },
  toTimeFromNow(date: string | number | Date | Dayjs, isUtc = true) {
    if (dayjs(date).isValid())
      return isUtc
        ? dayjs(date).utc().fromNow()
        : dayjs.utc(date).tz('Asia/Bangkok').fromNow();

    return date;
  },
};

export { DateTime };
