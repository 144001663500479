import { COLORS } from '~constants/colors';

export const styles = {
  body: {
    //display: 'grid',
    padding: 0,
    minHeight: '100vh',
    //gridTemplateRows: '30px 1fr',
    //padding: '5px'
    backgroundColor: COLORS.white,
  },
  mainContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'start',
    backgroundColor: COLORS.white,
  },
  topNav: {
    padding: 0,
    backgroundColor: COLORS.white,
    width: '100vw',
  },
  sideMenu: {
    backgroundColor: COLORS.lightGrey,
    minHeight: '100vh',
  },
  content: {
    padding: '10px',
    minHeight: '100vh',
    backgroundColor: COLORS.white,
    // borderTop: '1px',
    // borderTopColor: COLORS.lightGrey,
    // borderTopStyle: 'solid',
  },
  menu: {
    //backgroundColor: '#363b00',
    columns: '1fr',
    justifyContent: 'start',
    justifyItems: 'start',
    padding: 10,
  },
  subContent: {
    padding: '10px',
    height: '95%',
  },
  grid: {
    display: 'grid',
  },
  box: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '5px',
  },
  roundBox: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: '10px',
    borderRadius: '5px',
    alignContent: 'center',
    width: '100%',
  },
  colors: {
    black: '#363b42',
    yellow: '#ffb900',
    white: '#ffffff',
  },
};
