import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Row,
  Space,
  Tooltip,
  Table,
  notification,
  Pagination,
  Radio,
} from 'antd';
import * as React from 'react';
import { createState, useState } from '@hookstate/core';
import Box from '~components/common/Box';
import { faPlus, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { RoleModel } from '~types/roleModels';
import useRoleApi from '~root/src/apis/useRoleApi';
import { useEffect } from 'react';
import CreateRoleFormDrawer from './CreateRoleFormDrawer';
import { parseStateAsJson } from '~utils/transform';
import useResourceApi from '~root/src/apis/useResourceApi';
import { ResourceModel } from '~types/resourceModels';
import { ActionCell } from '~components/common/Cells';
import useRights from '~hooks/useRights';
import { rolePagedStore } from '~stores/roleStores';
import tables from '~constants/tables';
import { SearchQuery } from '~types/searchQuery';
import { PagedResultModel } from '~types/pagedResultModels';
import SearchBox from '~components/common/SearchBox';
import { exportDataToExcel } from '~utils/excelExport';
import { ActiveStatus } from '~constants/activeStatus';
import { DateTime } from '~utils/format';

interface UserProps {
  accessKey: string;
}

export const RESOURCES = createState<ResourceModel[]>([]);
export const SELECTED_ROLE = createState<RoleModel | undefined>(undefined);

export default function Role({ accessKey }: UserProps) {
  const {
    getRoles,
    create,
    update,
    destroy,
    exportToExcel: exportRoles,
  } = useRoleApi();
  const { fetch: fetchResources } = useResourceApi();
  const { hasCreateRights, hasUpdateRights, hasDeleteRights } = useRights();

  const rolesPaged = useState(rolePagedStore);
  const resources = useState(RESOURCES);
  const selectedRole = useState(SELECTED_ROLE);
  const loading = useState(false);
  const drawerVisible = useState(false);
  const page = useState(1);
  const pageSize = useState(tables.PAGE_SIZE);
  const searchWords = useState('');
  const deleted = useState<number>(ActiveStatus.Active);

  const setFormData = (id: string) => {
    const role = rolesPaged.value.records.find((r) => r.id === id);
    if (role) selectedRole.set(parseStateAsJson(role));
  };

  const handleRead = () => console.log('handleRead');
  const handleEdit = (id: string) => {
    setFormData(id);
    openDrawer();
  };

  const handleDelete = async (id: string) => {
    const result = await destroy(id);
    if (result.status === 200 || result.status === 204) {
      notification.success({
        message: 'Data was successfully deleted',
      });

      setRoles();
    } else
      notification.error({
        message: result.statusText,
      });
  };

  const columns = React.useMemo(
    () => [
      {
        title: '',
        dataIndex: '#operation',
        width: 80,
        render: (text: any, record: any) => (
          <ActionCell
            record={record}
            canRead={false}
            canEdit={hasUpdateRights('role')}
            canDelete={hasDeleteRights('role')}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Deleted',
        dataIndex: 'deleted',
        render: (value: any) => <label>{value.toString()}</label>,
      },
      {
        title: 'Created By',
        dataIndex: 'createdUserName',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (text: string) => (
          <label>{DateTime.toStandardDateTime(text)}</label>
        ),
      },
      {
        title: 'Updated By',
        dataIndex: 'modifiedUserName',
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (text: string) => (
          <label>{DateTime.toStandardDateTime(text)}</label>
        ),
      },
    ],
    []
  );

  const setRoles = async () => {
    loading.set(true);
    const query: SearchQuery = {
      page: page.value,
      size: pageSize.value,
      search: searchWords.value,
      deleted: deleted.value,
    };
    const result = await getRoles(query);
    const { status, data } = result;

    if (status !== 500) {
      const pagedData = data as PagedResultModel<RoleModel>;
      rolesPaged.set(pagedData);
    }

    loading.set(false);
  };

  const setResources = async () => {
    const result = await fetchResources();
    const { data } = result;
    const resourceModels = data as ResourceModel[];
    resources.set(resourceModels);
  };

  const openDrawer = () => drawerVisible.set(true);
  const closeDrawer = () => drawerVisible.set(false);

  const startSearch = async (search: string) => {
    searchWords.set(search);
    setRoles();
  };

  const handlePageChanged = (pageNumber: number, size?: number) => {
    page.set(pageNumber);
    pageSize.set(size ?? tables.PAGE_SIZE);
    setRoles();
  };

  const exportToExcel = async () => {
    loading.set(true);
    const query: SearchQuery = {
      page: 0,
      size: 0,
      search: searchWords.value,
      deleted: deleted.value,
    };
    await exportDataToExcel({
      fetch: async () => exportRoles(query),
    });
    loading.set(false);
  };

  useEffect(() => {
    setRoles();
    setResources();
  }, []);

  useEffect(() => {
    setRoles();
  }, [deleted.value]);

  return (
    <Box>
      <h2>Roles</h2>

      <Row>
        {/* <Col span={24}>
          <SearchBox onEnter={startSearch} placeholder='Enter to search' />
        </Col> */}
        <Col span={24} style={{ textAlign: 'start' }}>
          <Space>
            <Tooltip title='Export'>
              <Button
                type='default'
                shape='circle'
                icon={
                  <FontAwesomeIcon
                    icon={faFileExport}
                    onClick={exportToExcel}
                  />
                }
              />
            </Tooltip>

            {hasCreateRights('role') && (
              <Tooltip title='Add new role'>
                <Button
                  type='primary'
                  shape='circle'
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={openDrawer}
                />
              </Tooltip>
            )}

            <SearchBox onEnter={startSearch} placeholder='Enter to search' />
            <Radio.Group
              onChange={(e) => deleted.set(e.target.value)}
              value={deleted.value}
            >
              <Radio value={ActiveStatus.All}>All</Radio>
              <Radio value={ActiveStatus.Active}>Active</Radio>
              <Radio value={ActiveStatus.Deleted}>Deleted</Radio>
            </Radio.Group>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={rolesPaged.value.records}
        columns={columns}
        size='middle'
        loading={loading.value}
        pagination={false}
        rowKey='id'
      />
      <Pagination
        total={rolesPaged.value.totalRecords}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={pageSize.value}
        pageSize={pageSize.value}
        defaultCurrent={1}
        current={rolesPaged.value.currentPage}
        onChange={handlePageChanged}
      />
      {drawerVisible.value && (
        <CreateRoleFormDrawer
          data={selectedRole.value}
          visible={drawerVisible.value}
          closeDrawer={closeDrawer}
          onSuccess={setRoles}
        />
      )}
    </Box>
  );
}
