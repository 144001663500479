import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

//import { routes as ROUTES } from '~constants/path'
import { RouteProps } from '~types/route';
import AppRoute from './AppRoute';

interface RenderRoutesProps {
  routes: RouteProps[];
}

function RenderRoutes({ routes }: RenderRoutesProps) {
  return (
    <Switch>
      {routes.map(({ key, ...rest }) => (
        <AppRoute key={key} {...rest} />
      ))}

      {/* <Redirect to={'/not-found'} /> */}
      {/* <Route key='not-found' path='/not-found' /> */}
    </Switch>
  );
}

export default RenderRoutes;
