import { AxiosRequestConfig } from 'axios';

export const defaultAxiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  // transformResponse: [
  //   function (data) {
  //     // Do whatever you want to transform the data
  //     console.log('transformReponse', data);
  //     return data;
  //   },
  // ],
};
