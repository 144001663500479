import { DataTableResult } from '~types/dataTable';
import { DataTable } from '~types/tables';

const mapToOptions = (ids: any, options: Array<any>) => {
  if (!ids && !options) return [];
  return ids !== undefined && options.length > 0
    ? Array.isArray(ids)
      ? options.filter((v) => ids.includes(v.value))
      : options.find((v) => ids === v.value)
    : [];
};

const createOptions = (data: Array<any>, value: string, label: string) => {
  const defaultOpt = { value: '', label: '' };
  return data
    ? [
        //defaultOpt,
        ...data
          .filter((row) => row) // filter without undefined
          .map((row) => {
            return { value: row[value], label: row[label] };
          }),
      ]
    : [];
};

const createDatableResult = (data: Array<any>): DataTableResult<any> => {
  return {
    status: 200,
    data: {
      rows: data,
      totalRows: data.length,
    },
  };
};

const createDatable = (data: Array<any>): DataTable<any> => {
  return {
    rows: data,
    totalRows: data.length,
  };
};

const parseStateAsJson = (state: any) => JSON.parse(JSON.stringify(state));

export {
  mapToOptions,
  createOptions,
  createDatable,
  createDatableResult,
  parseStateAsJson,
};
