import useApi from '~hooks/useApi';
import {
  AssetModel,
  AssetQueryModel,
  UpdateAssetAgingModel,
  UpdateAssetModel,
} from '~types/assetModels';

export interface AssetQueryProps {
  page: number;
  size: number;
  search: string;
  deleted: number;
  exportAsTemplate?: boolean;
}

export interface AssetLogQueryProps {
  id: string;
  page: number;
  size: number;
  search: string;
  deleted: number;
}

const endpoint = 'assets';
export default function useAssetApi() {
  const {
    instance: api,
    find,
    get,
    post,
    put,
    delete: remove,
    exportFile,
    postExportFile,
  } = useApi();

  const getAssets = async (query: AssetQueryProps) => {
    return await find(endpoint, query);
  };

  const getAssetLogs = async (query: AssetLogQueryProps) => {
    const url = `${endpoint}/${query.id}/logs`;
    return await find(url, query);
  };

  const advSearch = async (query: AssetQueryModel) => {
    return await post(`${endpoint}/search`, query);
  };

  const getAsset = async (id: string) => {
    const url = `${endpoint}/${id}`;
    return await get(url);
  };

  const getAssetLog = async (id: string) => {
    const url = `${endpoint}/logs/${id}/detail`;
    return await get(url);
  };

  const getAssetInfo = async (assetId: string) => {
    const url = `${endpoint}/assetinfo?code=${encodeURIComponent(assetId)}`;
    return await get(url);
  };

  const checkAsset = async (assetId: string) => {
    const url = `${endpoint}/checkasset/${assetId}`;
    return await get(url);
  };

  const create = async (payload: UpdateAssetModel) => {
    return await post(endpoint, payload);
  };

  const update = async (payload: UpdateAssetModel) => {
    return await put(`${endpoint}/${payload.id}`, payload);
  };

  const resetAgingDay = async (payload: UpdateAssetAgingModel) => {
    return await put(`${endpoint}/aging/reset`, payload);
  };

  const destroy = async (id: string) => {
    return await remove(`${endpoint}/${id}`);
  };

  const exportAssets = async (query: AssetQueryProps) => {
    const url = `${endpoint}/export`;
    return await exportFile(url, { ...query, page: 0 });
  };

  const exportAssetLogs = async (query: AssetLogQueryProps) => {
    const url = `${endpoint}/${query.id}/logs/export`;
    return await exportFile(url, query);
  };

  const exportAdvSearch = async (query: AssetQueryModel) => {
    return await postExportFile(`${endpoint}/search/export`, query);
  };

  const deleteAttachment = async (id: string) => {
    return await remove(`${endpoint}/${id}/attachment`);
  };

  const deleteAttachmentSaga = async (id: string) => {
    return await remove(`${endpoint}/${id}/attachment/saga`);
  };

  const bulkDelete = async (ids: string[]) => {
    return await post(`${endpoint}/bulkDelete`, ids);
  };

  return {
    getAssets,
    getAssetLogs,
    getAssetLog,
    getAsset,
    getAssetInfo,
    checkAsset,
    advSearch,
    exportAdvSearch,
    create,
    update,
    resetAgingDay,
    destroy,
    exportAssets,
    exportAssetLogs,
    deleteAttachment,
    deleteAttachmentSaga,
    bulkDelete,
  };
}
