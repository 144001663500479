import * as React from 'react';
import Box from '~components/common/Box';
import { useState } from '@hookstate/core';
import { useEffect } from 'react';
import useRights from '~hooks/useRights';
import useAssetApi from '../../apis/useAssetApi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AssetDetailModel } from '~types/assetModels';
import AssetForm from './AssetForm';

interface AssetProps {
  accessKey: string;
}

export default function AssetDetail({ accessKey }: AssetProps) {
  const { id } = useParams<any>();
  const { getAsset } = useAssetApi();
  const { hasCreateRights, hasReadRights, hasUpdateRights, hasDeleteRights } =
    useRights();
  const asset = useState<AssetDetailModel | undefined>(undefined);
  const loading = useState(false);

  const prepareData = async () => {
    loading.set(true);
    const res = await getAsset(id);
    asset.set(res.data);
    loading.set(false);
  };

  useEffect(() => {
    prepareData();
  }, [id]);

  return (
    <Box>
      {/* <h2>Asset</h2> */}
      <AssetForm data={asset.value} />
    </Box>
  );
}
