import useApi from '~hooks/useApi';
import { DataTableResult } from '~types/dataTable';
import { SearchQuery } from '~types/searchQuery';
import { CreateUserModel, UserModel } from '~types/userModel';
import buildQueryString from '~utils/buildQueryString';

export interface UserQueryProps {
  page: number;
  size: number;
  search: string;
  deleted: number;
}

const endpoint = 'users';
export default function useUserApi() {
  const {
    instance: api,
    get,
    post,
    put,
    delete: remove,
    exportFile,
  } = useApi();
  const getUsers = async (query: SearchQuery) => {
    const url = `${endpoint}${buildQueryString(query)}`;
    return await get(url);
  };

  const getUser = async (id: string) => {
    const url = `${endpoint}/${id}`;
    return await get(url);
  };

  const fetchRights = async () => {
    return await get(`${endpoint}/mine/rights`);
  };

  const getMyProfile = async () => {
    const url = `${endpoint}/me`;
    return await get(url);
  };

  const create = async (payload: CreateUserModel) => {
    return await post(endpoint, payload);
  };

  const update = async (payload: CreateUserModel) => {
    return await put(`${endpoint}/${payload.id}`, payload);
  };

  const destroy = async (id: string) => {
    return await remove(`${endpoint}/${id}`);
  };

  const exportToExcel = async (query: SearchQuery) => {
    const url = `${endpoint}/export`;
    return await exportFile(url, { ...query, page: 0 });
  };

  return {
    getUsers,
    getUser,
    fetchRights,
    getMyProfile,
    create,
    update,
    destroy,
    exportToExcel,
  };
}
