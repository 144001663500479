import {
  faEdit,
  faEye,
  faTrash,
  faCaretDown,
  faCaretUp,
  faBell,
  faSignOutAlt,
  faUser,
  faChevronLeft,
  faCaretLeft,
  faSearch,
  faInbox,
  faHistory,
  faFileImport,
  faFileUpload,
  faCheckCircle,
  faTimesCircle,
  faCog,
  faFile,
  faFileAlt,
  faChartBar,
  faChartLine,
  faHome,
  faUsersCog,
  faClipboardList,
  faDatabase,
  faTable,
  faMailBulk,
  faFileDownload,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  faEdit,
  faEye,
  faTrash,
  faCaretDown,
  faCaretUp,
  faBell,
  faSignOutAlt,
  faUser,
  faChevronLeft,
  faCaretLeft,
  faSearch,
  faInbox,
  faHistory,
  faFileImport,
  faFileUpload,
  faCheckCircle,
  faTimesCircle,
  faCog,
  faFile,
  faFileAlt,
  faChartBar,
  faChartLine,
  faHome,
  faUsersCog,
  faClipboardList,
  faDatabase,
  faTable,
  faMailBulk,
  faExclamationCircle
);
