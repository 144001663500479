import { ATTACHMENT_TYPE } from '~constants/attachment';
import { Attachment } from '~types/assetModels';
import { base64ToArrayBuffer } from './base64ToArrayBuffer';

export const downloadFile = (atm: Attachment) => {
  const buffer = base64ToArrayBuffer(atm.blob);
  const type =
    atm.type === ATTACHMENT_TYPE.Pdf
      ? 'application/pdf'
      : 'text/plain;charset=utf-8';
  const blob = new Blob([buffer], { type });
  saveAs(blob, atm.name);
};
