import React from 'react';
import { createState, useState } from '@hookstate/core';
import { PagedResultModel } from '~types/pagedResultModels';
import { RoleModel } from '~types/roleModels';

const rolePagedStore = createState<PagedResultModel<RoleModel>>({
  totalPages: 0,
  totalRecords: 0,
  currentPage: 0,
  pageSize: 0,
  records: [],
});

export { rolePagedStore };
