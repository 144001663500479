import loadable from '@loadable/component';

import Dashboard from '~components/Dashboard';
import User from '~components/user/User';
import Role from '~components/role/Role';
import Asset from '~components/asset/Asset';
import AssetDetail from '~components/asset/AssetDetail';

//import { routes as ROUTES, keys as KEYS, aggregateType as AGGREGATE_TYPE } from '~constants/path'
import { RouteConfig } from '~types/route';
import NotFound from '~components/NotFound';
import CreateAsset from '~components/asset/CreateAsset';
import AssetLog from '~components/asset/AssetLog';
import AssetLogDetail from '~components/asset/AssetLogDetail';
import ErrorPage from '~components/ErrorPage';

const routeConfig: RouteConfig[] = [
  // {
  //     path: '/not-found',
  //     key: 'not-found',
  //     isPublic: true,
  //     component: loadable(() => import('~components/NotFound')),
  // },
  {
    path: '/auth', //ROUTES.auth,
    key: 'auth', //KEYS.auth,
    isPublic: true,
    component: loadable(() => import('~components/Auth')),
  },
  {
    path: '/signin-oidc', //ROUTES.signInRedirect,
    key: 'signin-oidc', //KEYS.signInRedirect,
    isPublic: true,
    component: loadable(() => import('~components/SignInRedirect')),
  },
  {
    path: '/signout-oidc', //ROUTES.signOutRedirect,
    key: '/signout-oidc', //KEYS.signOutRedirect,
    component: loadable(() => import('~components/SignOutRedirect')),
  },
  {
    path: '/error',
    key: 'error',
    isPublic: true,
    exact: true,
    component: loadable(() => import('~components/ErrorPage')),
  },
  {
    path: '/privacy',
    key: 'privacy',
    exact: true,
    isPublic: true,
    component: loadable(() => import('~components/Privacy')),
  },
  {
    path: '/not-found',
    key: 'not-found',
    isPublic: true,
    component: loadable(() => import('~components/NotFound')),
  },
  {
    path: '/',
    key: 'main',
    component: loadable(() => import('../containers/Main')),
    routes: [
      {
        path: '/dashboard',
        key: 'dashboard',
        isPublic: true,
        component: Dashboard,
      },
      {
        path: '/user',
        key: 'user',
        component: User,
      },
      {
        path: '/role',
        key: 'role',
        component: Role,
      },
      {
        path: '/asset',
        key: 'asset',
        exact: true,
        component: loadable(() => import('~components/asset/Asset')),
      },
      {
        path: '/asset/new',
        key: 'asset.create',
        exact: true,
        component: loadable(() => import('~components/asset/CreateAsset')),
      },
      {
        path: '/asset/import',
        key: 'asset.import',
        exact: true,
        component: loadable(() => import('~components/asset/ImportAsset')),
      },
      {
        path: '/asset/:id',
        key: 'asset.detail',
        exact: true,
        component: AssetDetail,
      },
      {
        path: '/asset/:id/logs',
        key: 'asset.logs',
        exact: true,
        component: loadable(() => import('~components/asset/AssetLog')),
      },
      {
        path: '/asset/logs/:id/detail',
        key: 'asset.logs.detail',
        exact: true,
        component: loadable(() => import('~components/asset/AssetLogDetail')),
      },
      {
        path: '/job',
        key: 'job',
        exact: true,
        component: loadable(() => import('~components/job/Job')),
      },
      {
        path: '/job/new',
        key: 'job.create',
        exact: true,
        component: loadable(() => import('~components/job/JobForm')),
      },
      {
        path: '/job/:id',
        key: 'job.detail',
        exact: true,
        component: loadable(() => import('~components/job/JobDetail')),
      },
      {
        path: '/noti-job',
        key: 'notification.job',
        exact: true,
        component: loadable(
          () => import('~components/notification/Notification')
        ),
      },
      {
        path: '/noti-job/:id',
        key: 'notification.job.detail',
        exact: true,
        component: loadable(
          () => import('~components/notification/NotificationDetail')
        ),
      },
      {
        path: '/noti-asset',
        key: 'notification.asset',
        exact: true,
        component: loadable(
          () => import('~components/notification/AssetNotification')
        ),
      },
      {
        path: '/noti-asset/:id',
        key: 'notification.asset.detail',
        exact: true,
        component: loadable(
          () => import('~components/notification/AssetNotificationDetail')
        ),
      },
      {
        path: '/costcenter',
        key: 'master.costcenter',
        exact: true,
        component: loadable(() => import('~components/master-data/CostCenter')),
      },
      {
        path: '/assettype',
        key: 'master.assettype',
        exact: true,
        component: loadable(() => import('~components/master-data/AssetType')),
      },
      {
        path: '/assettypedetail',
        key: 'master.assettypedetail',
        exact: true,
        component: loadable(
          () => import('~components/master-data/AssetTypeDetail')
        ),
      },
      {
        path: '/assetclass',
        key: 'master.assetclass',
        exact: true,
        component: loadable(() => import('~components/master-data/AssetClass')),
      },
      {
        path: '/assetstatus',
        key: 'master.assetstatus',
        exact: true,
        component: loadable(
          () => import('~components/master-data/AssetStatus')
        ),
      },
      {
        path: '/location',
        key: 'master.location',
        exact: true,
        component: loadable(() => import('~components/master-data/Location')),
      },
      {
        path: '/soldto',
        key: 'master.soldto',
        exact: true,
        component: loadable(() => import('~components/master-data/SoldTo')),
      },
      {
        path: '/shipto',
        key: 'master.shipto',
        exact: true,
        component: loadable(() => import('~components/master-data/ShipTo')),
      },
      {
        path: '/mailgroup',
        key: 'admin.mailgroup',
        exact: true,
        component: loadable(() => import('~components/admin/MailGroup')),
      },
      {
        path: '/report/daily-asset',
        key: 'report.daily-asset',
        exact: true,
        component: loadable(
          () => import('~components/report/AssetDailyReport')
        ),
      },
      {
        path: '/report/aging',
        key: 'report.aging',
        exact: true,
        component: loadable(() => import('~components/report/AgingReport')),
      },
    ],
  },
];

export default routeConfig;
