import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Col,
  Drawer,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Form,
  Table,
  notification,
  Pagination,
  Radio,
} from 'antd';
import * as React from 'react';
import Box from '~components/common/Box';
import { faPlus, faFileExport } from '@fortawesome/free-solid-svg-icons';
import Grid from '~components/common/Grid';
import useUserApi, { UserQueryProps } from '~root/src/apis/useUserApi';
import { UserModel } from '~types/userModel';
import { createState, useState } from '@hookstate/core';
import CreateUserFormDrawer from './CreateUserFormDrawer';
import { parseStateAsJson } from '~utils/transform';
import { ActionCell } from '~components/common/Cells';
import { useEffect } from 'react';
import { userPagedStore, usersStore, userStore } from '~stores/userStores';
import useRights from '~hooks/useRights';
import SearchBox from '~components/common/SearchBox';
import tables from '~constants/tables';
import { PagedResultModel } from '~types/pagedResultModels';
import { exportDataToExcel } from '~utils/excelExport';
import { SearchQuery } from '~types/searchQuery';
import useProfile from '~hooks/useProfile';
import { ActiveStatus } from '~constants/activeStatus';
import { DateTime } from '~utils/format';

interface UserProps {
  accessKey: string;
}

export default function User({ accessKey }: UserProps) {
  const {
    getUsers,
    create,
    update,
    destroy,
    exportToExcel: exportUsers,
  } = useUserApi();
  const { hasCreateRights, hasUpdateRights, hasDeleteRights } = useRights();
  const { profile } = useProfile();
  const usersPaged = useState(userPagedStore);
  const selectedUser = useState(userStore);
  const loading = useState(false);
  const drawerVisible = useState(false);
  const page = useState(1);
  const pageSize = useState(tables.PAGE_SIZE);
  const searchWords = useState('');
  const deleted = useState<number>(ActiveStatus.Active);

  const columns = React.useMemo(
    () => [
      {
        title: '',
        dataIndex: '#operation',
        //width: 80,
        render: (text: any, record: any) => (
          <ActionCell
            record={record}
            canRead={false}
            canEdit={hasUpdateRights('user')}
            canDelete={hasDeleteRights('user')}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ),
      },
      {
        title: 'Username',
        dataIndex: 'userName',
        //sorter: (a: any, b: any) => a.userName > b.userName,
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Role',
        dataIndex: 'roleName',
      },
      {
        title: 'Active',
        dataIndex: 'active',
        render: (active: any) => <span>{`${active}`}</span>,
      },
      {
        title: 'Deleted',
        dataIndex: 'deleted',
        render: (value: any) => <label>{value.toString()}</label>,
      },
      {
        title: 'Created By',
        dataIndex: 'createdUserName',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (text: string) => (
          <label>{DateTime.toStandardDateTime(text)}</label>
        ),
      },
      {
        title: 'Updated By',
        dataIndex: 'modifiedUserName',
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        render: (text: string) => (
          <label>{DateTime.toStandardDateTime(text)}</label>
        ),
      },
    ],
    []
  );

  const setFormData = (id: string) => {
    const user = usersPaged.value.records.find((u) => u.id === id);
    selectedUser.set(parseStateAsJson(user));
  };

  const handleEdit = (id: string) => {
    setFormData(id);
    openDrawer();
  };
  const handleDelete = async (id: string) => {
    const result = await destroy(id);
    if (result.status === 200 || result.status === 204) {
      notification.success({
        message: 'Data was successfully deleted',
      });

      setUsers();
    } else
      notification.error({
        message: result.statusText,
      });
  };

  const setUsers = async () => {
    loading.set(true);
    const query: UserQueryProps = {
      page: page.value,
      size: pageSize.value,
      search: searchWords.value,
      deleted: deleted.value,
    };
    const result = await getUsers(query);
    const { status, data } = result;

    if (status !== 500) {
      const pagedData = data as PagedResultModel<UserModel>;
      usersPaged.set(pagedData);
    }

    loading.set(false);
  };

  const openUserForm = () => {
    selectedUser.set(undefined);
    openDrawer();
  };
  const openDrawer = () => drawerVisible.set(true);
  const closeDrawer = () => drawerVisible.set(false);

  const startSearch = async (search: string) => {
    searchWords.set(search);
    setUsers();
  };

  const handlePageChanged = (pageNumber: number, size?: number) => {
    page.set(pageNumber);
    pageSize.set(size ?? tables.PAGE_SIZE);
    setUsers();
  };

  const exportToExcel = async () => {
    loading.set(true);
    const query: SearchQuery = {
      page: 0,
      size: 0,
      search: searchWords.value,
      deleted: ActiveStatus.Active,
    };
    await exportDataToExcel({
      fetch: async () => exportUsers(query),
    });
    loading.set(false);
  };

  useEffect(() => {
    setUsers();
  }, [deleted.value]);

  return (
    <Box>
      <h2>Users</h2>

      <Row>
        {/* <Col span={12}>
          <SearchBox onEnter={startSearch} placeholder='Enter to search' />
        </Col> */}
        <Col span={24} style={{ textAlign: 'start' }}>
          <Grid justifyContent='start' alignContent='space-around'>
            <Space>
              <Tooltip title='Export'>
                <Button
                  type='default'
                  shape='circle'
                  icon={
                    <FontAwesomeIcon
                      icon={faFileExport}
                      onClick={exportToExcel}
                    />
                  }
                />
              </Tooltip>
              {hasCreateRights('user') && (
                <Tooltip title='Add new user'>
                  <Button
                    type='primary'
                    shape='circle'
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={openUserForm}
                  />
                </Tooltip>
              )}
              <SearchBox onEnter={startSearch} placeholder='Enter to search' />
              <Radio.Group
                onChange={(e) => deleted.set(e.target.value)}
                value={deleted.value}
              >
                <Radio value={ActiveStatus.All}>All</Radio>
                <Radio value={ActiveStatus.Active}>Active</Radio>
                <Radio value={ActiveStatus.Deleted}>Deleted</Radio>
              </Radio.Group>
            </Space>
          </Grid>
        </Col>
      </Row>
      <Table
        dataSource={usersPaged.value.records}
        columns={columns}
        size='middle'
        //onChange={onPageChange}
        loading={loading.value}
        pagination={false}
        rowKey='id'
      />
      <Pagination
        total={usersPaged.value.totalRecords}
        showTotal={(total) => `Total ${total} items`}
        defaultPageSize={pageSize.value}
        pageSize={pageSize.value}
        defaultCurrent={1}
        current={usersPaged.value.currentPage}
        onChange={handlePageChanged}
      />
      <CreateUserFormDrawer
        data={selectedUser.value}
        visible={drawerVisible.value}
        closeDrawer={closeDrawer}
        onSuccess={setUsers}
        readonlyFields={profile?.isAdmin ? [] : ['userName', 'email']}
      />
    </Box>
  );
}
