import * as React from 'react';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import { CreateUserModel, UserModel } from '~types/userModel';
import { State, StateMethods, useState } from '@hookstate/core';
import useRoleApi from '~root/src/apis/useRoleApi';
import { RoleModel } from '~types/roleModels';
import { createOptions } from '~utils/transform';
import regEx from '~constants/regex';
import useUserApi from '~root/src/apis/useUserApi';
import MESSAGES from '~constants/messages';
import { SearchQuery } from '~types/searchQuery';
import tables from '~constants/tables';
import { PagedResultModel } from '~types/pagedResultModels';
import { ActiveStatus } from '~constants/activeStatus';

export interface CreateUserProps {
  firstName: string;
  lastName: string;
  email: string;
  department: any;
  role: any;
}

interface UserFormProps {
  data?: UserModel;
  visible: boolean;
  readonlyFields?: string[];
  closeDrawer(): void;
  onSuccess?(): void;
}

export default function CreateUserFormDrawer({
  data,
  visible,
  readonlyFields,
  closeDrawer,
  onSuccess,
}: UserFormProps) {
  const [form] = Form.useForm();
  const { getRoles } = useRoleApi();
  const { create, update } = useUserApi();
  const roles = useState<RoleModel[]>([]);
  const editMode = useState(data?.id ? true : false);
  const saving = useState(false);
  const activated = useState(true);

  const onFinish = async () => {
    const values: UserModel = await form.validateFields();

    try {
      saving.set(true);
      const result = data?.id
        ? await update({ ...values, id: data.id, active: activated.value })
        : await create({ ...values, active: activated.value });
      if (
        result &&
        (result.status === 200 ||
          result.status === 201 ||
          result.status === 204)
      ) {
        notification.success({
          key: 'user_create_success',
          message: MESSAGES.saveSuccessfully,
          //description: "Click 'refresh' icon on data grid to see new data",
        });
        if (typeof onSuccess === 'function') onSuccess();
      }
    } catch (error) {
      console.log(['error', error]);
      const {
        data: { status, detail, title },
      } = error;
      notification.error({
        key: 'user_create_failed',
        message: MESSAGES.saveFailure,
        description: `Error: ${status} / ${detail}`,
      });
    } finally {
      reset();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setRoles = async (
    page: number = 1,
    size: number = tables.PAGE_SIZE,
    search: string = ''
  ) => {
    const query: SearchQuery = {
      page: page,
      size: size,
      search: search,
      deleted: ActiveStatus.Active,
    };
    const result = await getRoles(query);
    const { status, data } = result;

    if (status !== 500) {
      const pagedData = data as PagedResultModel<RoleModel>;
      roles.set(pagedData.records);
    }
  };

  const reset = () => {
    closeDrawer();
    form.resetFields();
    saving.set(false);
  };

  const isReadonly = (field: string) => {
    return (
      data &&
      data.id.length > 0 &&
      readonlyFields &&
      readonlyFields.includes(field)
    );
  };

  React.useEffect(() => {
    setRoles();
  }, []);

  React.useEffect(() => {
    form.resetFields();
    editMode.set(data?.id ? true : false);
  }, [data]);

  return (
    <Drawer
      title={data?.id ? 'User / Update' : 'User / Create'}
      width={400}
      onClose={closeDrawer}
      visible={visible}
      mask={false}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Space>
            <Button onClick={closeDrawer}>Cancel</Button>
            <Button type='primary' onClick={onFinish} loading={saving.value}>
              Save
            </Button>
          </Space>
        </div>
      }
    >
      <Form
        form={form}
        name='userForm'
        layout={'vertical'}
        labelCol={{ span: 16 }}
        wrapperCol={{ span: 24 }}
        initialValues={data}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label='Username'
          name='userName'
          rules={[
            {
              required: true,
              min: 5,
              message: '',
            },
          ]}
        >
          <Input disabled={isReadonly('userName')} />
        </Form.Item>
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: '',
            },
          ]}
        >
          <Input type='email' disabled={isReadonly('email')} />
        </Form.Item>
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: '' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Role'
          name='roleId'
          rules={[{ required: true, message: '' }]}
        >
          <Select
            style={{ width: '100%' }}
            options={createOptions(roles.value, 'id', 'name')}
            disabled={isReadonly('roleId')}
          />
        </Form.Item>
        <Form.Item
          label='Active'
          name='active'
          valuePropName='checked'
          rules={[{ required: false, message: '' }]}
        >
          <Switch
            checkedChildren='Yes'
            unCheckedChildren='No'
            defaultChecked={true}
            onClick={() => activated.set(!activated.value)}
            disabled={isReadonly('active')}
          />
        </Form.Item>

        {data?.id && (
          <Divider>
            <Button
              type='primary'
              ghost
              danger
              onClick={() => editMode.set(!editMode.value)}
            >
              Change Password
            </Button>
          </Divider>
        )}
        {!editMode.value && (
          <div>
            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  required: data?.id ? false : true,
                  pattern: regEx.password,
                  message: '',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            {!data?.id && (
              <Form.Item
                label='Confirm Password'
                name='confirmPassword'
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: true, min: 8, message: '' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            )}
          </div>
        )}
      </Form>
    </Drawer>
  );
}
