import * as React from 'react'
import { styles } from '~styles/Styles'

interface GridProps {
    height?: string;
    columns?: string;
    rows?: string;
    alignContent?: 'center' | 'start' | 'end' | 'space-between' | 'space-around';
    alignItems?: 'center' | 'start' | 'end' | 'space-between' | 'space-around';
    justifyContent?: 'center' | 'start' | 'end' | 'space-between' | 'space-around';
    justifyItems?: 'center' | 'start' | 'end' | 'space-between' | 'space-around';
    bgColor?: string;
    children: React.ReactNode;
}


const Grid = ({ 
    height,
    columns = 'auto', 
    rows = 'auto',
    alignContent = 'start', 
    alignItems = 'center',
    justifyContent = 'start', 
    justifyItems = 'start',
    bgColor = 'transparent', 
    children }: GridProps) => <div style={{
        ...styles.grid, 
        height,
        gridTemplateColumns: columns,
        gridTemplateRows: rows,
        alignContent,
        alignItems,
        justifyContent,
        justifyItems,
        backgroundColor: bgColor
    }}>
        {children}
    </div>

export default Grid