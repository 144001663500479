import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { IconName } from '@fortawesome/free-solid-svg-icons';

interface AppIconProps extends FontAwesomeIconProps {
  cursor?: string | undefined;
  // icon: IconName;
  // size?: string;
  // style?: string;
  // title?: string;
  text?: string;
  position?: 'left' | 'right';
  color?: string;
  onClick?(): void;
}

const StyledFontAwesomeIcon = styled(({ bordered, position, ...props }) => (
  <span>
    {position === 'left' && <FontAwesomeIcon {...props} />}
    {` ${props.text} `}
    {position === 'right' && <FontAwesomeIcon {...props} />}
  </span>
))`
  ${(props) =>
    css`
      cursor: ${props.cursor || 'auto'};
    `}
  ${(props) =>
    props.bordered &&
    css`
      border-style: solid;
      border-radius: 50px;
      border-width: 1px;
      border-color: darkgray;
      background-color: white;
      padding: 5px;
      :hover {
        border-color: var(--antd-wave-shadow-color);
      }
    `}
`;

const AppIcon = ({
  cursor = 'auto',
  icon,
  size = 'sm',
  style,
  title,
  text,
  position = 'left',
  color,
  onClick,
}: AppIconProps) => (
  <StyledFontAwesomeIcon
    cursor={cursor}
    icon={icon}
    size={size}
    style={style}
    color={color}
    title={title}
    onClick={onClick}
    position={position}
    text={text || ''}
  >
    {text || ''}
  </StyledFontAwesomeIcon>
);

export default AppIcon;
