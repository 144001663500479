const black = '#000000';
const darkGrey = '#3c464b';
const lightGrey = '#eaebeb';
const yellow = '#ffb900';
const white = '#ffffff';
const red = '#cc0000';
const green = '#009697';
const blue = 'rgb(24,144,255)';
export const COLORS = {
  backgroundColor: {
    body: white,
    topNav: black,
    sideMenu: lightGrey,
    menu: darkGrey,
  },
  black,
  darkGrey,
  lightGrey,
  yellow,
  white,
  red,
  green,
  blue,
};
