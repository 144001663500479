import { UploadFile } from 'antd/lib/upload/interface';

const isExcel = (file: UploadFile) => {
  return (
    /\.(xlsx|xls)$/i.test(file.name) ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );
};

const isImage = (file: UploadFile) => {
  return /\.(png|jpg|jpeg|gif)$/i.test(file.name);
};

const isPdf = (file: UploadFile) => {
  return /\.(pdf)$/i.test(file.name);
};

const isMsWord = (file: UploadFile) => {
  return (
    /\.(doc|docx)$/i.test(file.name) ||
    file.type === 'application/msword' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  );
};

export { isExcel, isImage, isPdf, isMsWord };
