import useApi from '~hooks/useApi';
import { RoleModel } from '~types/roleModels';
import { SearchQuery } from '~types/searchQuery';
import buildQueryString from '~utils/buildQueryString';

const endpoint = 'roles';
export default function useRoleApi() {
  const {
    instance: api,
    get,
    exportFile,
    post,
    put,
    delete: remove,
  } = useApi();
  const getRoles = async (query: SearchQuery) => {
    const url = `${endpoint}${buildQueryString(query)}`;
    return await get(url);
  };

  const getRole = async (id: string) => {
    const url = `${endpoint}/${id}`;
    return await get(url);
  };

  const create = async (payload: RoleModel) => {
    return await post(endpoint, payload);
  };

  const update = async (payload: RoleModel) => {
    return await put(`${endpoint}/${payload.id}`, payload);
  };

  const destroy = async (id: string) => {
    return await remove(`${endpoint}/${id}`);
  };

  const exportToExcel = async (query: SearchQuery) => {
    const url = `${endpoint}/export`;
    return await exportFile(url, { ...query, page: 0 });
  };

  return {
    getRoles,
    getRole,
    create,
    update,
    destroy,
    exportToExcel,
  };
}
